<template>
  <div>
    <RSpinnerOverlay :loading="loading" :is-full-page="true" />
    <router-view :company="company" :property="property" />
  </div>
</template>

<script>
/**
 * BaseProperty
 *
 * Exposes company, property objects as props to all child components.
 */
import { RSpinnerOverlay } from '@/components/spinners'
import { CraigslistAPI } from '@/services/api/resources'
import { getFeatures } from '@/utils'
import constants from '@/utils/constants'

export default {
  name: 'BaseProperty',
  /**
   * React to param changes to refresh the property data.
   */
  beforeRouteUpdate (to, from, next) {
    if (to.params.pid !== from.params.pid) {
      this.fetchProperty(to.params.pid)
    }
    next()
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('clear', 'selectedProperty')
    next()
  },
  components: {
    RSpinnerOverlay
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      property: null,
      loading: false
    }
  },
  created () {
    this.fetchProperty(this.$route.params.pid)
  },
  methods: {
    /**
     * Fetch the full property object from the api
     *
     * @param {Number} id
     */
    async fetchProperty (id) {
      this.loading = true
      const response = await CraigslistAPI.properties.retrieve({ id })
      if (response) {
        this.property = response.data
        const selectedProperty = response.data.property
        selectedProperty.features = getFeatures(selectedProperty, status => status !== constants.rooof.Status.INACTIVE)
        this.$store.commit('set', ['selectedProperty', selectedProperty])
      }
      this.loading = false
    }
  }
}
</script>
